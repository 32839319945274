@import "./theme";

.d-flex {
  display: flex;
}
.d-inline {
  display: inline;
}
.row {
  flex-direction: row;
}
.column {
  flex-direction: column;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.flex-1 {
  flex: 1;
}
.flex-wrap {
  flex-wrap: wrap;
}
.m-10{
  margin: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-30 {
  margin-right: 30px;
}
.p-20 {
  padding: 20px;
}
.w-100 {
  width: 100% !important;
}
.w-80 {
  width: 80% !important;
}
.w-60 {
  width: 60% !important;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.text-danger {
  color: $dangerColor;
}

.callout-danger {
  background-color: $backgroundColorDark;
  padding: 12px;
  border-radius: 10px;
  > svg {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }
}

.text-normal {
  color: $primaryFontColor;
}
.text-primary {
  color: $primaryColor;
}

.text-white {
  color: $light !important;
}

.title-text {
  font-size: 24px;
  font-weight: 800;
  color: $primaryFontColor;
}

.display-none {
  display: none;
}

.separator-text {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 12px;
}

.separator-text::before,
.separator-text::after {
  content: "";
  flex: 1;
  height: 1px;
  background-color: $greyColor;
}

.separator-text:not(:empty)::before {
  margin-right: 0.5em;
}

.separator-text:not(:empty)::after {
  margin-left: 0.5em;
}

.spinner {
  border: 2px solid $primaryDarkColor;
  border-radius: 50%;
  border-top: 2px solid transparent;
  width: 24px !important;
  height: 24px !important;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}
.close-icon {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-self: flex-end;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
