@import "./theme";

.project-search-input {
  height: 48px;
  width: 100%;
  border-radius: 24px;
  border: 1px solid $greyColor;
  padding-left: 18px;
  display: flex;
  align-items: center;
  color: $primaryFontColor;
  svg {
    height: 18px;
    margin-right: 8px;
  }
  > input {
    border: 0px;
    flex-grow: 1;
    background-color: transparent;
    color: $primaryFontColor;
  }
}

.project-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
.project {
  position: relative;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid $greyColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &:hover {
    cursor: pointer;
    background-color: $backgroundColorDark;
    border: 1px solid $primaryColor;
  }

  .project-name {
    font-weight: 600;
    margin-top: 6px;
    font-size: 12px;
  }
  .project-country {
    font-size: 10px;
    margin-top: 6px;
    font-weight: 400;
    opacity: 0.6;
  }
}

.project-organisation-image {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 0px 3px 6px -3px rgba($color: #000000, $alpha: 0.16);
}
.no-project-organisation-image {
  background-color: $primaryDarkColor;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

@include lgLaptopView {
  .project-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
